import React, { useRef, useState } from 'react';
import './App.css';
import ReactECharts from 'echarts-for-react';
import { LineSeriesOption } from 'echarts/charts'
import { Input } from 'antd';
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import { Col, Button, Divider, Typography, Row, Space } from 'antd';

const { Text } = Typography

//https://codesandbox.io/s/react-xiongdizujiantongxin-f2jf6?file=/src/App.js 


interface IMainState {
	data: Data
}

class Main extends React.Component<any, IMainState> {
	constructor(props: any) {
		super(props)
		this.state = { data: { left1: [], left2: [], right1: [], right2: [] } };
		this.setData = this.setData.bind(this)
	}
	setData(key: string, index: number, value: number) {
		console.log("submit: ", key, index, value)
		this.setState((state: IMainState) => {
			var val: number[] = []
			switch (key) {
				case "left1": {
					val = state.data.left1
					break
				}
				case "left2": {
					val = state.data.left2
					break
				}
				case "right1": {
					val = state.data.right1
					break
				}
				case "right2": {
					val = state.data.right2
					break
				}
			}
			val[index - 1] = value
			return state
		});
	}
	render() {
		return (
			<div className="main">
				<div className="input-area">
					<InputArea data={this.state.data} setData={this.setData} />
				</div>
				<div>
					<Button type="primary" onClick={download} data-html2canvas-ignore={true} >保存</Button>
				</div>
				<div id="charts-area" className="charts-area">
					<Row justify='center'>
						<Col span={12} className='chart-row'>
							<Chart name="left" line1={this.state.data.left1} line2={this.state.data.left2} />
						</Col>
						<Col span={12} className='chart-row'>
							<Chart name="right" line1={this.state.data.right1} line2={this.state.data.right2} />
						</Col>
					</Row>
				</div>
			</div >
		)
	}
}

interface IProps {
	setData: (key: string, index: number, value: number) => void
	data: Data
}

interface IState {
	data: string
}

interface Data {
	left1: number[] // one line, [1,2,3,4] means [1,2] [3,4]
	left2: number[]
	right1: number[]
	right2: number[]
}

class InputArea extends React.Component<IProps, IState>{
	constructor(props: IProps) {
		super(props)
		this.state = { data: "" }
		this.onChangeLeft1 = this.onChangeLeft1.bind(this)
	}
	onChangeLeft1(key: string, index: number, val: number) {
		console.log("input area: ", key, index, val)
		this.props.setData(key, index, val)
	}
	render() {
		return (
			<div>
				<SeriesArea label="左1" keyName="left1" onChange={this.onChangeLeft1} />
				<SeriesArea label="左1" keyName="left2" onChange={this.onChangeLeft1} />
				<SeriesArea label="右1" keyName="right1" onChange={this.onChangeLeft1} />
				<SeriesArea label="右2" keyName="right2" onChange={this.onChangeLeft1} />
			</div >
		)
	}
}

interface ISeriesAreaProps {
	label: string
	keyName: string
	onChange: (key: string, index: number, val: number) => void
}

class SeriesArea extends React.Component<ISeriesAreaProps, {}> {
	constructor(props: any) {
		super(props)
	}

	changeVal(name: string, e: any) {
		var index = Number(name)
		var value = Number(e.target.value)
		console.log("series area: ", this.props)
		this.props.onChange(this.props.keyName, index, value)
	}

	render() {
		return (
			<Row wrap={false} gutter={10} className="input-row">
				<Col span={2}>
					<Text>{this.props.label}</Text>
				</Col>
				<Col span={3}>
					<Space.Compact>
						<Input placeholder="坐标1" onChange={this.changeVal.bind(this, "1")} />
						<Input placeholder="" onChange={this.changeVal.bind(this, "2")} />
					</Space.Compact>
				</Col>
				<Col span={3}>
					<Space.Compact>
						<Input placeholder="坐标2" onChange={this.changeVal.bind(this, "3")} />
						<Input placeholder="" onChange={this.changeVal.bind(this, "4")} />
					</Space.Compact>
				</Col>
				<Col span={3}>
					<Space.Compact>
						<Input placeholder="坐标3" onChange={this.changeVal.bind(this, "5")} />
						<Input placeholder="" onChange={this.changeVal.bind(this, "6")} />
					</Space.Compact>
				</Col>
				<Col span={3}>
					<Space.Compact>
						<Input placeholder="坐标4" onChange={this.changeVal.bind(this, "7")} />
						<Input placeholder="" onChange={this.changeVal.bind(this, "8")} />
					</Space.Compact>
				</Col>
				<Col span={3}>
					<Space.Compact>
						<Input placeholder="坐标5" onChange={this.changeVal.bind(this, "9")} />
						<Input placeholder="" onChange={this.changeVal.bind(this, "10")} />
					</Space.Compact>
				</Col>
				<Col span={3}>
					<Space.Compact>
						<Input placeholder="坐标6" onChange={this.changeVal.bind(this, "11")} />
						<Input placeholder="" onChange={this.changeVal.bind(this, "12")} />
					</Space.Compact>
				</Col>
			</Row>
		)
	}
}

const download = () => {
	var node = document.getElementById('charts-area');
	if (node) {
		domtoimage
			.toPng(node)
			.then(function(dataUrl: any) {
				var img = new Image();
				img.src = dataUrl;
				saveAs(dataUrl, "ear.png");
			})
			.catch(function(error: any) {
				console.error("oops, something went wrong!", error);
			});
	}
};

interface IChartProps {
	name: string
	line1: number[]
	line2: number[]
}

class Chart extends React.Component<IChartProps, object> {
	getOption(): LineSeriesOption[] {
		console.log("get option: ", this.props.line1, this.props.line2)
		var line1 = turn(this.props.line1)
		var line2 = turn(this.props.line2)
		return [
			{
				name: '1',
				type: 'line',
				data: line1
			},
			{
				name: '2',
				type: 'line',
				data: line2,
			}
		]
	}
	render() {
		var option = {
			title: {
				text: this.props.name
			},
			tooltip: {
				trigger: 'axis'
			},
			legend: {
				data: ['1', '2']
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true,
			},
			xAxis: [
				{
					type: 'value',
					name: 'Hz',
					min: 125,
					max: 8000,
					position: 'top',
					nameGap: 5,
					axisLine: {
						onZero: false,
					}
				}
			],
			yAxis: [
				{
					type: 'value',
					name: 'dB',
					min: -20,
					max: 120,
					nameLocation: 'end',
					nameGap: 1,
					inverse: true,
				}
			],
			series: this.getOption()
		}
		return (
			<ReactECharts
				option={option}
				style={{ height: 400 }}
			/>
		)
	}
}

function turn(val: number[]): number[][] {
	var arr = []
	for (var i = 0; i < val.length; i = i + 2) {
		arr.push([val[i], val[i + 1]])
	}
	return arr
}

function App() {
	return (
		<body className="App">
			<Main />
		</body>
	);
}

export default App;
